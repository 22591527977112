import React from 'react'
import classes from './CalculatorImage.module.css'

const CalculatorImage = ({ maxHeight = 260, maxWidth = 320 }) => {
  return (
    <div
      className={classes.calculatorsImage}
      style={{ maxWidth: maxWidth, maxHeight: maxHeight }}
    >
      <img style={{ maxWidth: maxWidth, maxHeight: maxHeight }} src={maxWidth === 464 ? '/calculator-big.png' :  '/calculator-home-page.jpg'} alt='Kalkulatori' />
      <div>
        <h3>Kako koristiti BETKO kalkulatore?</h3>
        <div className={classes.bottomWrapper}>
          <p>Saznaj više klikom na dugme ispod</p>
          <button>Kalkulatori</button>
        </div>
      </div>
    </div>
  )
}

export default CalculatorImage
