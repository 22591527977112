import { Outlet } from 'react-router-dom'
// import Footer from '../components/Footer'
// import TitleNav from '../components/TitleNav'
// import BetKurs from '../components/BetKurs'
// import BetKalkulatori from '../components/BetKalkulatori'
import classes from './homeLayout.module.css'
import Header from '../components/Redesign/Header/Header'
import News from '../components/News'
import Sidebar from '../components/Redesign/Sidebar/Sidebar'
import { useState } from 'react'
const HomeLayout = ({ betkoShop }) => {
  const [isSidebarActive, setIsSidebarActive] = useState(false)

  const closeSidebar = () => setIsSidebarActive(false)

  return (
    <>
      <div style={{ position: 'relative', zIndex: 100 }}>
        <Header
          setIsSidebarActive={setIsSidebarActive}
          isSidebarActive={isSidebarActive}
        />
        {isSidebarActive && <Sidebar onClose={closeSidebar} />}
        <News />
      </div>
      <div className={classes.container}>{betkoShop || <Outlet />}</div>
    </>
  )
}

export default HomeLayout
