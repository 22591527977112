import { useNavigate } from 'react-router-dom'
import classes from './singlePost.module.css'
import { useState } from 'react'
import React from 'react'
import ReactPlayer from 'react-player'

const SERVER_URL = process.env.REACT_APP_SERVER_URL

const SinglePost = ({ postId, type, post, commentNumbers }) => {
  const navigate = useNavigate()
  const SERVER_URL = process.env.REACT_APP_SERVER_URL
  const [numOfCommentsArr, setNumOfCommentsArr] = useState([])

  const handleContextMenu = (event) => {
    event.preventDefault()
  }

  return (
    <div
      className={classes.container}
      onClick={() => {
        if (type === 'infoBlok') {
          navigate(`/info_blok/${postId}`)
        }
        if (type === 'betPortal') {
          navigate(`/bet_portal/${postId}`)
        }
        if (type === 'statistika') {
          navigate(`/statistika/${postId}`)
        }
        if (type === 'betKursNovo') {
          navigate(`/bet_kurs_novo/${postId}`)
        }
        if (type === 'nasiPredlozi') {
          navigate(`/nasi_predlozi/${postId}`)
        }
        if (type === 'betKalkulatoriTutorijal') {
          navigate(`/bet_kalkulatori_tutorijal/${postId}`)
        }
        if (type === 'korisnickiPaketi') {
          navigate(`/korisnicki_paketi/${postId}`)
        }
      }}
    >
      {post && post.featureVideo && post.featureVideo.url ? (
        <div className={classes.featureVideoDiv}>
          <ReactPlayer
            url={`${SERVER_URL}/${post.featureVideo?.url}`}
            playing={true} // Enables autoplay
            muted={true} // Mutes the video
            loop
            width='320px'
            height='267px'
            onContextMenu={handleContextMenu}
          />
        </div>
      ) : (
        <img
          className={classes.featureImage}
          src={`${SERVER_URL}/${post.featureImage?.url}`}
        ></img>
      )}
      <div className={classes.title}>
        <h2>{post.title}</h2>
      </div>
    </div>
  )
}

export default SinglePost
