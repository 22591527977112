import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import classes from './Sidebar.module.css'
import { menuItems, socialMediaData } from './SidebarData'
const Sidebar = ({ onClose }) => {
  const [activeMenuItem, setActiveMenuItem] = useState(null)
  const navigate = useNavigate()

  useEffect(() => {
    document.body.style.overflow = 'hidden'
    return () => {
      document.body.style.overflow = 'auto'
    }
  }, [])

  const toggleMenu = (menuItem) => {
    setActiveMenuItem((prev) => (prev === menuItem ? null : menuItem))
  }

  const handleNavigation = (url) => {
    navigate(url)
    onClose() // Zatvori Sidebar nakon navigacije
  }

  return (
    <div className={classes.sidebar}>
      <div className={classes.authBtnWrapper}>
        <button onClick={() => handleNavigation('/registracija_i_logovanje')}>
          Prijavi se
        </button>
      </div>

      <div className={classes.linksContainer}>
        {menuItems.map((menuItem, index) => (
          <div key={index} className={classes.menuItem}>
            <button
              onClick={() => {
                if (menuItem.children) {
                  toggleMenu(menuItem.name)
                } else {
                  handleNavigation(menuItem.url)
                }
              }}
              className={`${classes.menuButton} ${
                activeMenuItem === menuItem.name ? classes.activeMenuItem : ''
              }`}
            >
              <div className={`imageWrapper ${classes.itemIconWrapper}`}>
                <img
                  src={`/icons/sidebar/${menuItem.icon}.svg`}
                  alt={menuItem.name}
                />
              </div>
              {menuItem.name}
              {menuItem.children && (
                <div className={`imageWrapper ${classes.submenuIconWrapper}`}>
                  <img src='/icons/submenu-active.svg' alt='submenu icon' />
                </div>
              )}
            </button>
            {activeMenuItem === menuItem.name && menuItem.children && (
              <div className={classes.submenu}>
                {menuItem.children.map((child, childIndex) => (
                  <button
                    key={childIndex}
                    onClick={() => handleNavigation(child.url)}
                    className={classes.submenuButton}
                  >
                    {child.name}
                  </button>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>
      <div className={classes.sidebarFooter}>
        <div className={classes.sidebarFooterLinks}>
          <button onClick={() => handleNavigation('/politika-privatnosti')}>
            Politika privatnosti
          </button>
          <button onClick={() => handleNavigation('/opsti-uslovi')}>
            Opšti uslovi
          </button>
        </div>
        <div className={classes.sidebarSocialMediaBtns}>
          {socialMediaData.map((item, index) => (
            <button key={index} onClick={() => handleNavigation(item.url)}>
              <img
                src={`/icons/social-media/${item.name}.svg`}
                alt={item.name}
              />
            </button>
          ))}
        </div>
      </div>
    </div>
  )
}

export default Sidebar
