import { useNavigate, useLocation } from 'react-router-dom'
import classes from './adminAddNewForm.module.css'
import useAxiosPrivate from '../hooks/useAxiosPrivate'
import { useState, useRef, useEffect } from 'react'
import {
  Modal,
  notification,
  Input,
  InputNumber,
  Select,
  Button,
  Checkbox,
} from 'antd'
import Axios from 'axios'
import { DeleteOutlined } from '@ant-design/icons'
import Pagination from "../components/Pagination";

const { TextArea } = Input
const { Option } = Select

const AdminAddNewHomePageVideo = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const [list, setList] = useState()
  const [title, setTitle] = useState()
  const [pageTitle, setPageTitle] = useState()
  const [reRender, setReRender] = useState()
  const [imageType, setImageType] = useState('')
  const [textField, setTextField] = useState('')
  const [images, setImages] = useState([])
  const [filteredImages, setFilteredImages] = useState([])

  const [videos, setVideos] = useState([])
  const [isVisibleVideoModal, setIsVisibleVideoModal] = useState(false)
  const [selectedFeatureImage, setSelectedFeatureImage] = useState()
  const [videoType, setVideoType] = useState('')
  const [selectedFeatureVideo, setSelectedFeatureVideo] = useState()
  const [selectedMarketingVideo, setSelectedMarketingVideo] = useState()
  const [selectedMarketingImage, setSelectedMarketingImage] = useState()
  const [items, setItems] = useState([])
  const [marketingLink, setMarketingLink] = useState('')
  const [itemIndex, setItemIndex] = useState([])
  const [isVisibleModal, setIsVisibleModal] = useState(false)
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(false)
  const axiosPrivate = useAxiosPrivate()
  const SERVER_URL = process.env.REACT_APP_SERVER_URL

  const getPageTitle = () => {
    setPageTitle('Home page video listu')
  }

  const fetchVideos = async () => {
    try {
      const videosArr = await Axios.get(`${SERVER_URL}/home-page-videos`, {
        withCredentials: false,
      })

      setVideos(videosArr.data)
    } catch (error) {
      console.log('err', error)
    }
  }

  const fetchVideo = async () => {
    try {
      const list = await Axios.get(`${SERVER_URL}/home-page-video-list`, {
        withCredentials: false,
      })

      if (list && list.data && list.data.items) {
        setList(list.data.items)
        // setTitle(list.data[0].label)
        // // console.log('list data', list.data[0]);
        setItems(list.data.items)
      }
    } catch (error) {
      console.log('err', error)
    }
  }

  // console.log('VIDEOS', items)

  const handleShowVideoModal = () => {
    setIsVisibleVideoModal(true)
  }

  const handleRemoveFromAdding = (index) => {
    let array = items
    array.splice(index, 1)

    setItems(array)
    setReRender(!reRender)
  }

  // console.log('location state', location)

  const handleSubmit = async () => {
    try {
      // console.log('ITEMS', items)
      const data = {
        items,
      }

      setIsSaveButtonDisabled(true)
      await axiosPrivate.post(`${SERVER_URL}/home-page-videos`, data)

      notification.success({
        message: 'Uspešno postavljeni home page video snimci.',
      })

      setTimeout(() => {
        window.location.reload()
      }, 300)
    } catch (error) {
      notification.error({ message: error })
    }
  }

  const handleShowModal = (e) => {
    if (e === 'featureVideo') {
      setIsVisibleVideoModal(true)
      setVideoType(e)
    } else if (e === 'marketingVideo') {
      setIsVisibleVideoModal(true)
      setVideoType(e)
    } else {
      setImageType(e)
      setIsVisibleModal(true)
    }
  }

  const handleCancel = () => {
    setIsVisibleModal(false)
    setIsVisibleVideoModal(false)
  }

  const [pageNumber, setPageNumber] = useState(1)
  const [totalPages, setTotalPages] = useState(null)

  const paginationProps = {
    currentPage: pageNumber,
    setCurrentPage: setPageNumber,
    buttonLimit: 3,
    totalPages: totalPages,
  }

  const fetchImages = async () => {
    try {
      const imagesArr = await Axios.get(`${SERVER_URL}/images/get-all?page=${pageNumber}&limit=50`, {
        withCredentials: false,
      });
      setImages(imagesArr.data.results);
      setFilteredImages(imagesArr.data.results);
      setTotalPages(imagesArr.data.totalPages)
    } catch (error) {
      console.log("err", error);
    }
  };

  useEffect(() => {
    fetchImages()
    fetchVideos()
    getPageTitle()
    fetchVideo()
  }, [pageNumber])

  const handleSelectImage = (image) => {
    if (imageType === 'featureImage') {
      setSelectedFeatureImage(image)
      setSelectedFeatureVideo(null)
    } else if (imageType === 'marketingImage') {
      setSelectedMarketingImage(image)
      setSelectedMarketingVideo(null)
    } else if (imageType === 'itemImage') {
      let arr = items

      arr[itemIndex].image = image

      setItems(arr)
    }
    setIsVisibleModal(false)
  }

  const handleChangeLink = (e, index) => {
    let arr = items

    arr[index].url = e.target.value

    setItems(arr)
    setReRender(!reRender)
  }

  const handleSelectVideo = (video) => {
    if (videoType == 'featureVideo') {
      setSelectedFeatureVideo(video)
      setSelectedFeatureImage(null)
      setIsVisibleVideoModal(false)
      setReRender(!reRender)
    } else if (videoType == 'marketingVideo') {
      setSelectedMarketingVideo(video)
      setSelectedMarketingImage(null)
      setIsVisibleVideoModal(false)
      setReRender(!reRender)
    } else {
      let arr = items

      arr[itemIndex].video = video

      setItems(arr)

      setIsVisibleVideoModal(false)
      setReRender(!reRender)
    }
  }

  const filterImages = (e) => {
    if (e) {
      let filtered = images.filter((item) => item.imageType === e)

      setFilteredImages(filtered)
    } else {
      setFilteredImages(images)
    }
  }

  // console.log(items)

  return (
    <div className={classes.container}>
      <h1 style={{ marginLeft: '10px' }}>Nova objava za {pageTitle}</h1>

      <div>
        <button
          className={classes.addImageBtn}
          onClick={() => {
            let array = items
            // console.log(items)
            let obj = { type: 'video', video: '', url: '' }
            array.push(obj)

            setItems([...array])
          }}
        >
          Dodaj video
        </button>
      </div>

      {items && items.length > 0 && (
        <div className={classes.labelRow}>Stavke </div>
      )}

      {items &&
        items.length > 0 &&
        items.map((item, index) => {
          return (
            <div className={classes.imageitem}>
              <div className={classes.imageItemLeft}>
                {item && item && item.video.url && (
                  <video
                    key={`${SERVER_URL}/${item.video.url}`}
                    width='320'
                    height='240'
                    controls
                  >
                    <source
                      src={`${SERVER_URL}/${item.video.url}`}
                      type='video/mp4'
                    />
                  </video>
                )}
                <button
                  className={classes.addImageBtn}
                  onClick={() => {
                    handleShowVideoModal()
                    setItemIndex(index)
                  }}
                >
                  {items[index] && items[index].video.url
                    ? items[index].video.url
                    : 'Izaberite video'}
                </button>
                <p>Link:</p>
                <Input
                  value={item.url}
                  className={classes.inputVideoLink}
                  onChange={(e) => {
                    handleChangeLink(e, index)
                  }}
                ></Input>
              </div>
              <Button
                icon={<DeleteOutlined />}
                type='primary'
                style={{
                  padding: '0px 0px 0px 20px',
                  width: '60px',
                  marginLeft: '15px',
                  display: 'flex',
                  alignItems: 'center',
                }}
                danger
                onClick={() => handleRemoveFromAdding(index)}
              ></Button>
            </div>
          )
        })}
      <Modal
        width={'80%'}
        footer={[]}
        title='Kliknite sliku da biste je odabrali'
        visible={isVisibleModal}
        onCancel={handleCancel}
      >
        <div>
          <Select
            style={{ minWidth: '200px' }}
            onChange={(e) => {
              filterImages(e)
            }}
            defaultValue={''}
          >
            <Option value=''>Sve</Option>
            <Option value='home'>Početna strana</Option>
            <Option value='gallery'>Gallery</Option>
          </Select>
        </div>
        <div className={classes.imagesList}>
          {filteredImages &&
            filteredImages.length > 0 &&
            filteredImages.map((item, index) => {
              return (
                <div className={classes.imageItem}>
                  <img
                    onClick={() => {
                      handleSelectImage(item)
                    }}
                    className='img'
                    src={`${SERVER_URL}/${item.url}`}
                    alt={item.url}
                  />
                </div>
              )
            })}
         <Pagination {...paginationProps}/>
        </div>
      </Modal>
      <Modal
        width={'80%'}
        footer={[]}
        title='VIDEO GALERIJA'
        visible={isVisibleVideoModal}
        onCancel={handleCancel}
      >
        <div className={classes.videosList}>
          {videos &&
            videos.length > 0 &&
            videos.map((item, index) => {
              return (
                <div className={classes.videoItem}>
                  <video width='320' height='240' controls>
                    <source
                      src={`${SERVER_URL}/${item.url}`}
                      type='video/mp4'
                    />
                  </video>
                  <Button
                    onClick={() => {
                      handleSelectVideo(item)
                    }}
                    className={classes.selectVideoButton}
                  >
                    Izaberite video
                  </Button>
                </div>
              )
            })}
        </div>
      </Modal>
      <div className={classes.statistikaFooter}>
        <button
          className={classes.submitButton}
          disabled={isSaveButtonDisabled}
          onClick={() => {
            handleSubmit()
          }}
        >
          Sačuvaj
        </button>
      </div>
    </div>
  )
}

export default AdminAddNewHomePageVideo
