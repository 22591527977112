import React, { useEffect, useState } from 'react'
import { Form, Input, message, notification } from 'antd'
import classes from './Payment.module.css'
import axios from 'axios'
import WsPayFrom from '../components/WsPayFrom'
import getCountryCode from '../utils/GetCountryCode'
import { useNavigate } from 'react-router-dom'
import PricesModal from '../components/PricesModal'
const SERVER_URL = process.env.REACT_APP_SERVER_URL

const Payment = () => {
  const [formValues, setFormValues] = useState(null)
  const navigate = useNavigate()
  const [isModalVisible, setIsModalVisbile] = useState(true)
  const [allPackages, setAllPackages] = useState([])
  const [selectedPackage, setSelectedPackage] = useState(null)
  const [form] = Form.useForm()

  const onFinish = async (values) => {
    if (!selectedPackage) {
      notification.error({ message: 'Molimo odaberite paket!' })
      return
    }
    try {
      const response = await axios.post(`${SERVER_URL}/create-key`, {
        packageId: selectedPackage._id,
      })

      localStorage.setItem('userPaymentInfo', JSON.stringify(values))

      setFormValues({
        ...values,
        CustomerCountry: getCountryCode(values?.CustomerCountry),
        Signature: response.data.hash,
        ShoppingCartID: response.data.shoppingCartID,
      })
    } catch (error) {
      message.error('Došlo je do greške pri slanju podataka.')
    }
  }

  // Set form values from previous transaction
  useEffect(() => {
    const userPaymentInfo = localStorage.getItem('userPaymentInfo')
    form.setFieldsValue(JSON.parse(userPaymentInfo))
  }, [])

  useEffect(() => {
    const getAllPackages = async () => {
      try {
        const response = await axios.get(`${SERVER_URL}/get-all-package`)
        setAllPackages(response?.data?.allPackages)
      } catch (err) {
        console.error('Error fetching packages:', err)
      }
    }

    getAllPackages()
  }, [])

  return (
    <div className={classes.paymentPageContainer}>
      {isModalVisible ? (
        <PricesModal
          isModalVisible={isModalVisible}
          setIsModalVisible={setIsModalVisbile}
          selectedPackage={selectedPackage}
          setSelectedPackage={setSelectedPackage}
          allPackages={allPackages}
        />
      ) : (
        <>
          <div className={classes.inner}>
            <div className={classes.prices}>
              <div className={classes.imgWrapper}>
                <PricesModal
                  allPackages={allPackages}
                  isModalVisible={isModalVisible}
                  setIsModalVisible={setIsModalVisbile}
                  selectedPackage={selectedPackage}
                  setSelectedPackage={setSelectedPackage}
                />
              </div>
              <button className={classes.formBtn} type='button'>
                UPLATI UPLATNICOM
              </button>
              <button
                onClick={() => navigate('/marketing_prostor')}
                className={classes.formBtn}
                type='button'
              >
                UPLATA MARKETINŠKOG PROSTORA
              </button>
              <button
                className={classes.formBtn}
                type='button'
                onClick={() => navigate('/sigurna_kupovina')}
              >
                SIGURNA KUPOVINA
              </button>
            </div>
            <div className={classes.formContainer}>
              <Form
                form={form}
                name='paymentForm'
                onFinish={onFinish}
                layout='vertical'
              >
                <div className={classes.formItem}>
                  <label>IME</label>
                  <Form.Item
                    name='CustomerFirstName'
                    rules={[{ required: true, message: 'Molimo unesite ime!' }]}
                  >
                    <Input placeholder='KLIKNI I UPIŠI SVOJE IME' />
                  </Form.Item>
                </div>
                <div className={classes.formItem}>
                  <label>PREZIME</label>
                  <Form.Item
                    name='CustomerLastName'
                    rules={[
                      { required: true, message: 'Molimo unesite prezime!' },
                    ]}
                  >
                    <Input placeholder='KLIKNI I UPIŠI SVOJE PREZIME' />
                  </Form.Item>
                </div>
                <div className={classes.formItem}>
                  <label>BETKO REG. E-MAIL</label>
                  <Form.Item
                    name='CustomerEmail'
                    rules={[
                      { required: true, message: 'Molimo unesite email!' },
                    ]}
                  >
                    <Input placeholder='KLIKNI I UPIŠI SVOJ BETKO EMAIL' />
                  </Form.Item>
                </div>
                <div className={classes.formItem}>
                  <label>ADRESA</label>
                  <Form.Item
                    name='CustomerAddress'
                    rules={[
                      { required: true, message: 'Molimo unesite adresu!' },
                    ]}
                  >
                    <Input placeholder='KLIKNI I UPIŠI ADRESU' />
                  </Form.Item>
                </div>
                <div className={classes.formItem}>
                  <label>MESTO</label>
                  <Form.Item
                    name='CustomerCity'
                    rules={[
                      { required: true, message: 'Molimo unesite mesto!' },
                    ]}
                  >
                    <Input placeholder='KLIKNI I UPIŠI MESTO' />
                  </Form.Item>
                </div>
                <div className={classes.formItem}>
                  <label>POŠTANSKI BROJ</label>
                  <Form.Item
                    name='CustomerZIP'
                    rules={[
                      {
                        required: true,
                        message: 'Molimo unesite poštanski broj!',
                      },
                    ]}
                  >
                    <Input placeholder='KLIKNI I UNESI POŠTANSKI BROJ' />
                  </Form.Item>
                </div>
                <div className={classes.formItem}>
                  <label>DRŽAVA</label>
                  <Form.Item
                    name='CustomerCountry'
                    rules={[
                      { required: true, message: 'Molimo unesite državu!' },
                    ]}
                  >
                    <Input placeholder='KLIKNI I UNESI DRŽAVU' />
                  </Form.Item>
                </div>
                <div className={classes.formItem}>
                  <label>BROJ TELEFONA</label>
                  <Form.Item
                    name='CustomerPhone'
                    rules={[
                      {
                        required: true,
                        message: 'Molimo unesite broj telefona!',
                      },
                    ]}
                  >
                    <Input placeholder='KLIKNI I UNESI BROJ TELEFONA' />
                  </Form.Item>
                </div>
                <div className={classes.cardImgWrapper}>
                  <img src='/payment-card-info.png' alt='payment card info' />
                </div>
                <button className={classes.formBtn} type='submit'>
                  KLIKNI I DOVRŠI UPLATU
                </button>

                <div className={classes.logotips}>
                  <div className={classes.cardsWrapper}>
                    <a
                      href='https://www.americanexpress.com/'
                      title='American Express'
                      target='_blank'
                    >
                      <img
                        src='/cards/AmericanExpress50.jpg'
                        alt='American Express'
                      />
                    </a>
                    <a href='https://www.visa.com' title='Visa' target='_blank'>
                      <img src='/cards/Visa50.gif' />
                    </a>
                    <a
                      href='http://www.mastercard.com'
                      title='MasterCard'
                      target='_blank'
                    >
                      <img src='/cards/MasterCard50.gif' />
                    </a>
                    <a
                      title='Maestro'
                      target='_blank'
                      href='http://www.maestrocard.com'
                    >
                      <img src='/cards/maestro50.gif' />
                    </a>
                    <a
                      title='Mastercard® Identity Check™'
                      target='_blank'
                      href='https://www.wspay.info/mastercard-securecode.html'
                    >
                      <img src='/cards/mastercard-identity-check.png' />
                    </a>

                    <a
                      title='Visa Secure'
                      target='_blank'
                      href='https://www.wspay.info/verified-by-visa.html'
                    >
                      <img src='/cards/visa-secure.png' />
                    </a>
                  </div>
                  <div className={classes.wsPayLogo}>
                    <a
                      href='http://www.wspay.me'
                      title='Monri WSpay - Web Secure Payment Gateway'
                      target='_blank'
                    >
                      <img
                        alt='Monri WSpay - Web Secure Payment Gateway'
                        src='https://www.wspay.info/payment-info/wsPayWebSecureLogo-118x50-transparent.png'
                        border='0'
                      />
                    </a>
                  </div>
                </div>
              </Form>
            </div>
          </div>
          <WsPayFrom
            formValues={formValues}
            TotalAmount={selectedPackage?.price}
          />
        </>
      )}
    </div>
  )
}

export default Payment
