export const menuItems = [
  {
    name: 'Pomoć',
    icon: 'help',
    children: [
      { name: 'Video tutorijali - korisnička podrška', url: '/' },
      { name: 'Video tutorijali za sve BET kalkulatore', url: '/' },
    ],
  },
  {
    name: 'Betko Family',
    icon: 'betko-family',
    children: [
      { name: 'Šta je betko family?', url: '/betko_family' },
      { name: 'Betko plus', url: '/betko_plus' },
    ],
  },
  {
    name: 'Betkogram',
    icon: 'betkogram',
    children: [
      { name: 'Glavne kartice', url: '/glavne-kartice' },
      { name: 'Sportske price', url: '/sportske-price' },
      { name: 'Info blok', url: '/info_blok' },
      { name: 'Bet portal', url: '/bet_portal' },
      { name: 'Statistika', url: '/statistika' },
      { name: 'Vaši predlozi', url: '/vasi_predlozi' },
      { name: 'BET', url: '/bet_portal/653e47fa52a098b10de971f2' },
      { name: 'NET', url: '/bet_portal/65611f28f4ca9761eb5249ad' },
    ],
  },
  {
    name: 'Korisnički paketi',
    icon: 'korisnicki-paketi',
    children: [
      { name: 'Opis BET kursa', url: '/bet_kurs_tutorijal' },
      { name: 'Opis BET kalukatora', url: '/bet_kalkulatori' },
      { name: 'Šta je BET kurs', url: '/bet_kurs' },
      {
        name: 'Šta je BET kalukator',
        url: '/korisnicki_paketi/65bae5faa86ff9a51a6b1b2b',
      },
      { name: 'BET kalkulatori', url: '/bet_kalkulatori_tutorijal' },
      {
        name: 'BET kurs i sistemi',
        url: '/korisnicki_paketi/65bae51ba86ff9a51a6b1ab4',
      },
      { name: 'PREMIUM', url: '/bet_kurs_novo' },
    ],
  },
  {
    name: 'Kupi paket',
    icon: 'kupi-paket',
    children: [
      { name: 'Kupi paket', url: '/korisnicki_paketi' },
      {
        name: 'Kupovina putem uplatnice',
        url: '/korisnicki_paketi/65bac125a86ff9a51a6b110f',
      },
    ],
  },
  {
    name: 'Otvori i osvoji',
    icon: 'otvori-i-osvoji',
  },
  {
    name: 'Betko shop',
    icon: 'betko-shop',
    children: [
      { name: 'Betko shop', url: '/betko_shop' },
      { name: 'Shop ', url: '/betko_shop/shop?shopType=shop' },
      { name: 'Licitacije', url: '/betko_shop/shop?shopType=auction' },
      { name: 'Sport Turist', url: '/betko_shop/shop?shopType=sport-tourist' },
    ],
  },
  {
    name: 'Rezultati uživo',
    icon: 'rezultati-uzivo',
    url: '/rezultati_uzivo',
  },
]

export const socialMediaData = [
  {
    name: 'facebook',
    url: '/',
  },
  {
    name: 'instagram',
    url: '/',
  },
  {
    name: 'tiktok',
    url: '/',
  },
  {
    name: 'telegram',
    url: '/',
  },
  {
    name: 'youtube',
    url: '/',
  },
]
