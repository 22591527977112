import React, { useContext, useEffect, useState } from 'react'
import classes from './Scorebat.module.css'
import Comments from '../components/Comments'
import AuthContext from '../context/AuthProvider'
import AppContext from '../context/AppContext'
import axios from '../config/axios'

export default function Scorebat() {
  const { auth } = useContext(AuthContext)
  const { openLiveComments, setOpenLiveComments } = useContext(AppContext)
  const [data, setData] = useState({})
  const [scorebat, setScorebat] = useState(1)
  const [commentCounter, setCommentCounter] = useState({
    totalComments: 0,
    commentsLength: 0,
    answersLength: 0,
  })

  const fetchData = async () => {
    const { data } = await axios.get(
      `/info-block-post/653f0fb252a098b10de9797a`
    )
    setData(data.post)
  }

  useEffect(() => {
    const script = document.createElement('script')
    script.src = 'https://www.scorebat.com/embed/embed.js?v=arrv'
    script.async = true
    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  }, [])

  useEffect(() => {
    fetchData()
  }, [])

  useEffect(() => {
    ;(function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0]
      if (d.getElementById(id)) return
      js = d.createElement(s)
      js.id = id
      js.src = 'https://www.scorebat.com/embed/embed.js?v=arrv'
      fjs.parentNode.insertBefore(js, fjs)
    })(document, 'script', 'scorebat-jssdk')
  }, [])

  const adminCommentOnly = data.adminCommentOnly ? data.adminCommentOnly : false

  const [isCommentsOpen, setIsComentsOpen] = useState(false)
  const toggleComments = () => {
    setIsComentsOpen(!isCommentsOpen)
  }

  return (
    <>
      <div className={`${classes.mainContainer}`}>
        <div className={`${classes.headerContainer}`}>
          <h1 className={`${classes.header}`}>REZULTATI UŽIVO</h1>
        </div>
        <div className={classes.contentContainer}>
          <div className={classes.contentHolder}>
            <div className={`${classes.buttonContainer}`}>
              <button
                className={`${classes.resultsButton} ${
                  scorebat === 1 ? classes.activeButton : classes.inactiveButton
                }`}
                onClick={() => setScorebat(1)}
                type='button'
              >
                <img
                  className={`${classes.dot} ${
                    scorebat === 1 ? classes.activeDot : classes.inactiveDot
                  }`}
                  src='/icons/blackDot.svg'
                  alt='Rezultati uživo'
                />
                Rezultati uživo
              </button>
              <button
                className={`${classes.liveStreamButton} ${
                  scorebat === 2 ? classes.activeButton : classes.inactiveButton
                }`}
                onClick={() => setScorebat(2)}
                type='button'
              >
                <img
                  className={`${classes.liveIcon} ${
                    scorebat === 2 ? (
                      classes.activeLiveIcon
                    ) : (
                      <img src='/icons.dot.svg' />
                    )
                  }`}
                  src='/icons/liveStream.svg'
                  alt='Live Stream'
                />
                Live Stream
              </button>
            </div>
            <div className={classes.scoreBatMainContainer}>
              {scorebat === 1 && (
                <iframe
                  src={`https://www.scorebat.com/embed/livescore/?token=${process.env.REACT_APP_SCOREBAT_EMBED_TOKEN}`}
                  frameBorder='0'
                  allowFullScreen
                  allow='autoplay; fullscreen'
                  className={`_scorebatEmbeddedPlayer_ ${classes.iFrame}`}
                  scrolling='auto'
                />
              )}
              {scorebat === 2 && (
                <iframe
                  src={`https://www.scorebat.com/embed/?token=${process.env.REACT_APP_SCOREBAT_EMBED_TOKEN}`}
                  frameBorder='0'
                  allowFullScreen
                  allow='autoplay; fullscreen'
                  className='_scorebatEmbeddedPlayer_'
                  scrolling='auto'
                ></iframe>
              )}
            </div>
          </div>

          <button
            className={`${classes.commentButton}`}
            onClick={toggleComments}
          >
            {isCommentsOpen
              ? 'Zatvori komentare'
              : 'Klikni da zajedno komentarišemo'}
          </button>

          <div
            className={`${classes.commentsContainer} ${
              isCommentsOpen ? classes.openComments : ''
            }`}
          >
            <button className={classes.closeButton} onClick={toggleComments}>
              Zatvori
            </button>
            <Comments
              commentType={'info_blok'}
              adminComment={adminCommentOnly}
              user={auth}
              id={'653f0fb252a098b10de9797a'}
              setCommentCounter={setCommentCounter}
            />
          </div>
        </div>
      </div>
    </>
  )
}
