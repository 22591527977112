import React, { useContext, useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import BetkoShopLayout from './BetkoShopLayout'
import { betkoShopUsloviData } from './BetkoShopUsloviData'
import { Button, Form, Input, message, Modal } from 'antd'
import axios from '../../config/axios'
import LoginModalNovo from '../../components/LoginModalNovo'
import AuthContext from '../../context/AuthProvider'
import classes from './BetkoShopSaradnja.module.css'
import useRefreshToken from '../../hooks/useRefreshToken'
const SERVER_URL = process.env.REACT_APP_SERVER_URL

const BetkoShopSaradnja = () => {
  const { auth } = useContext(AuthContext)
  const [isSellerModalVisible, setIsSellerModalVisible] = useState(false)
  const [isOpenLoginModal, setIsOpenLoginModal] = useState(false)
  const refresh = useRefreshToken()
  const [form] = Form.useForm()

  const [isMobile, setIsMobile] = useState(window.innerWidth < 768)
  const navigate = useNavigate()

  const handleRequestShop = async () => {
    try {
      const values = await form.validateFields()
      const response = await axios.post(
        `${SERVER_URL}/user/request-shop/${auth._id}`,
        { ...values }
      )
      message.success(response?.data || 'Zahtev je uspešno poslat!')
      setIsSellerModalVisible(false)
      refresh()
    } catch (error) {
      console.log(error)
      message.error(
        error?.response?.data?.message ||
          'Greška, pokušajte kasnije ili nas kontaktirajte!'
      )
    }
  }
  return (
    <BetkoShopLayout>
      <LoginModalNovo
        setIsOpenLoginModal={setIsOpenLoginModal}
        isOpenLoginModal={isOpenLoginModal}
        title='Da bi ste postali prodavac morate se ulogovati!'
      />
      <Modal
        title='Unesite podatke o kompaniji'
        open={isSellerModalVisible} // Set to true to display the modal for this example
        onOk={handleRequestShop}
        onCancel={() => {
          setIsSellerModalVisible(false)
          form.resetFields()
        }}
        footer={[
          <Button
            key='back'
            onClick={() => {
              setIsSellerModalVisible(false)
              form.resetFields()
            }}
          >
            Otkaži
          </Button>,
          <Button key='submit' type='primary' onClick={handleRequestShop}>
            Potvrdi
          </Button>,
        ]}
      >
        <Form form={form} layout='vertical' className={classes.cf}>
          <Form.Item
            name='companyName'
            label='Naziv kompanije'
            rules={[
              { required: true, message: 'Molimo unesite naziv kompanije!' },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name='companyAddress'
            label='Adresa kompanije'
            rules={[
              { required: true, message: 'Molimo unesite adresu kompanije!' },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name='companyPlace'
            label='Mesto kompanije'
            rules={[
              { required: true, message: 'Molimo unesite mesto kompanije!' },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name='companyPIB'
            label='PIB'
            rules={[
              { required: true, message: 'Molimo unesite PIB!' },
              { len: 9, message: 'PIB mora imati tačno 9 cifara!' },
              {
                pattern: /^[0-9]+$/,
                message: 'PIB može sadržavati samo cifre!',
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name='companyMIB'
            label='Matični broj'
            rules={[
              { required: true, message: 'Molimo unesite Matični broj!' },
              { len: 8, message: 'Matični broj mora imati tačno 8 cifara!' },
              {
                pattern: /^[0-9]+$/,
                message: 'Matični broj može sadržavati samo cifre!',
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
      <div className={`${classes.pageContainer}`}>
        <div className={classes.sticky}>
          <div className={classes.menuTitle}>
            <h2>KONTAKT - SARADNJA</h2>
          </div>
          <div className={classes.becomeBtns}>
            <div className={`${classes.navBtns} ${classes.becomeSellerBtn}`}>
              {auth.accessAdminShop === 'requested' ? (
                <button>ČEKA SE ODOBRENJE</button>
              ) : auth.accessAdminShop === 'approved' ? (
                <button
                  onClick={() =>
                    !isMobile && navigate('/betko-shop-admin/home')
                  }
                  className={classes.goToDash}
                >
                  KLIKOM NA OVO POLJE (ISKLJUČIVO NA RAČUNARU, NE I NA MOBILNOM
                  TELEFONU) MOŽETE PRISTUPITI VAŠEM ADMINISTRATORSKOM PROFILU.
                </button>
              ) : (
                <button
                  onClick={() => {
                    auth?._id
                      ? setIsSellerModalVisible(true)
                      : setIsOpenLoginModal(true)
                  }}
                >
                  KLIKNI I POSTANI PRODAVAC
                </button>
              )}
            </div>
            {(auth?.accessAdminShop === 'noAccess' || !auth?._id) && (
              <>
                <div
                  className={`${classes.navBtns} ${classes.becomeSellerBtn}`}
                >
                  {auth.accessAdminShop === 'requested' ? (
                    <button>ČEKA SE ODOBRENJE</button>
                  ) : auth.accessAdminShop === 'approved' ? (
                    <button
                      onClick={() =>
                        !isMobile && navigate('/betko-shop-admin/home')
                      }
                    >
                      KLIKOM NA OVO POLJE (ISKLJUČIVO NA RAČUNARU, NE I NA
                      MOBILNOM TELEFONU) MOŽETE PRISTUPITI VAŠEM
                      ADMINISTRATORSKOM PROFILU.
                    </button>
                  ) : (
                    <button
                      onClick={() => {
                        auth?._id
                          ? setIsSellerModalVisible(true)
                          : setIsOpenLoginModal(true)
                      }}
                    >
                      KLIKNI I POSTANI LICITATOR
                    </button>
                  )}
                </div>
                <div
                  className={`${classes.navBtns} ${classes.becomeSellerBtn}`}
                >
                  {auth.accessAdminShop === 'requested' ? (
                    <button>ČEKA SE ODOBRENJE</button>
                  ) : auth.accessAdminShop === 'approved' ? (
                    <button
                      onClick={() =>
                        !isMobile && navigate('/betko-shop-admin/home')
                      }
                    >
                      KLIKOM NA OVO POLJE (ISKLJUČIVO NA RAČUNARU, NE I NA
                      MOBILNOM TELEFONU) MOŽETE PRISTUPITI VAŠEM
                      ADMINISTRATORSKOM PROFILU.
                    </button>
                  ) : (
                    <button
                      onClick={() => {
                        auth?._id
                          ? setIsSellerModalVisible(true)
                          : setIsOpenLoginModal(true)
                      }}
                    >
                      KLIKNI I POSTANI ORGANIZATOR PUTA
                    </button>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
        <h3>POŠTOVANI IZLAGAČI</h3>
        <p>
          Naš sajt betkotip.com je zajednica koja se se bavi sportskim temama.
          Pozivamo vas, da ukoliko imate bilo kakvu ponudu, bilo kakvih
          proizvoda, koji su na bilo koji način povezivi sa sportom, što i ne
          moraju biti (kategorija ''Ostalo''), izložite svoju ponudu na
          betkotip.com
        </p>
        <p>
          Želimo da napomenemo, da je prostor vezan za meni "LICITACIJE"
          namenjen isključivo registrovanim humanitarnim fondacijama i
          organizacijama, koje deluju u potpunosti legalno. One su jedine, koje
          isključivo u humanitarne svrhe mogu da licitiraju predmete ili bilo
          šta, što je od javnog značaja ili ne, te postavljaju brojeve za slanje
          sms poruka ili brojeve računa u cilju da prikupe sredstva za pomoć
          onima kojima je ona najpotrebnija.
        </p>
        <p>
          Naš sajt vam nudi mogućnost da potpuno besplatno izložite svoju ponudu
          na betkotip.com, kao i da primate porudžbine ili ponude uz naše
          posredstvo, te da kroz proces realizacije istih pospešite svoju
          prodaju ili u slučaju da ste humanitarna organizacija, pomognete onima
          kojima je to najpotrebnije,bez ikakvih troškova izlaganja proizvoda
          ili ponude.
        </p>
        <p>
          Prvo što je potrebno jeste da obavite osnovnu registraciju na
          betkotip.com, što možete učiniti klikom na ovaj link:{' '}
          <a href='https://betkotip.com/registracija_i_logovanje'>LINK</a>
        </p>
        <p>
          Nakon što ste to učinili podnesite zahtev za otvaranje profila za
          izlaganje vašeg proizvoda/ponude, iznad ovog teksta na KLIKNI I
          POSTANI PRODAVAC, KLIKNI I POSTANI LICITATOR ili na KLIKNI I POSTANI
          ORGANIZATOR PUTA.
        </p>
        <p>
          Nakon što ste registrovali svoj izlagački profil, možete sami da
          postavljate svoje proizvode. Svaka postavka pre izlaganja na sajtu
          prolazi kroz naš pregled, nakon kog će se, u slučaju da je sve
          pravilno urađeno, vaša ponuda naći na betkotip.com .
        </p>

        <p>Izlaganje vaše ponude je potpuno besplatno.</p>
        <p>
          Ukoliko bi došlo do bilo kakvih promena po bilo kom pitanju vezanom za
          poslovnu politiku i uslove izlaganja na betkotip.com , betkotip.com se
          obavezuje da o tome pravovremeno informiše sve izlagače koji imaju
          postavku svojih proizvoda na našem sajtu.
        </p>
        <p>Ponuda važi samo za pravna lica.</p>
        <p>Vaš BETKO !</p>
      </div>
    </BetkoShopLayout>
  )
}

export default BetkoShopSaradnja
