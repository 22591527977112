import { useState, useEffect } from 'react'
import useAxiosPrivate from '../hooks/useAxiosPrivate'
import { useQuery } from '@tanstack/react-query'
import SinglePost from '../components/SinglePost'
import Spinner from '../components/Spinner'
import classes from './nasiPredlozi.module.css'
import LayoutWithCalcAndSideImgs from '../components/Redesign/LayoutWithCalcAndSideImgs/LayoutWithCalcAndSideImgs'

const NasiPredlozi = () => {
  const [posts, setPosts] = useState([])
  const [isMsg, setIsMsg] = useState(false)
  const axiosPrivate = useAxiosPrivate()

  const getAllNasiPredloziPosts = async () => {
    const { data } = await axiosPrivate.get('/getAllNasiPredloziPosts')
    data.msg ? setIsMsg(true) : setPosts(data.result)
    !data.msg ? setIsMsg(false) : setPosts([])
    return data
  }
  const allNasiPredloziPostsQuery = useQuery({
    queryKey: ['nasiPredlozi'],
    queryFn: getAllNasiPredloziPosts,
    staleTime: 0,
    refetchInterval: 1000 * 60 * 60 * 12,
  })

  return (
    <LayoutWithCalcAndSideImgs title='Naši Predlozi'>
      <div className={classes.container}>
        {allNasiPredloziPostsQuery.isLoading || posts.length === 0 ? (
          <div className={classes.spinner}>
            <Spinner />
          </div>
        ) : (
          <>
            {!isMsg ? (
              posts.map((post) => {
                return (
                  <SinglePost
                    commentNumbers={post?.commentsNumber}
                    key={post._id}
                    post={post}
                    type='nasiPredlozi'
                    postId={post._id}
                  />
                )
              })
            ) : (
              <div className={classes.msg}>
                <p>{allNasiPredloziPostsQuery.data.msg}</p>
              </div>
            )}
          </>
        )}
      </div>
    </LayoutWithCalcAndSideImgs>
  )
}

export default NasiPredlozi
