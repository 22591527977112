import { useNavigate, useLocation } from 'react-router-dom'
import classes from './adminPokreniKalkulatore.module.css'
import useAxiosPrivate from '../hooks/useAxiosPrivate'
import { useState, useRef, useEffect } from 'react'
import {
  Modal,
  notification,
  Input,
  InputNumber,
  Select,
  Button,
  Checkbox,
} from 'antd'
import Axios from 'axios'
import { DeleteOutlined } from '@ant-design/icons'
import svggore from './assets/arrowup.svg'
import svgdole from './assets/arrowdown.svg'
import Pagination from "../components/Pagination";

const { TextArea } = Input
const { Option } = Select

const AdminPokreniKalkulatore = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const [title, setTitle] = useState()
  const [editMode, setEditMode] = useState(false)
  const [data, setData] = useState()
  const [reRender, setReRender] = useState()
  const [imageType, setImageType] = useState('')
  const [videoType, setVideoType] = useState('')
  const [textField, setTextField] = useState('')
  const [images, setImages] = useState([])
  const [filteredImages, setFilteredImages] = useState([])
  const [videos, setVideos] = useState([])
  const [selectedFeatureImage, setSelectedFeatureImage] = useState()
  const [selectedMarketingImage, setSelectedMarketingImage] = useState()
  const [selectedFeatureVideo, setSelectedFeatureVideo] = useState()
  const [selectedMarketingVideo, setSelectedMarketingVideo] = useState()
  const [items, setItems] = useState([])
  const [oldItems, setOldItems] = useState([])
  const [marketingLink, setMarketingLink] = useState('')
  const [itemIndex, setItemIndex] = useState([])
  const [isVisibleModal, setIsVisibleModal] = useState(false)
  const [isVisibleVideoModal, setIsVisibleVideoModal] = useState(false)
  const [isVisibleVideoModalOldItems, setIsVisibleVideoModalOldItems] =
    useState(false)
  const axiosPrivate = useAxiosPrivate()
  const [isDelModalVisible, setIsDelModalVisible] = useState(false)
  const [isVisibleModalOldItems, setIsVisibleModalOldItems] = useState(false)
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(false)
  const [selectedItem, setSelectedItem] = useState()
  const SERVER_URL = process.env.REACT_APP_SERVER_URL

  const handleRemoveFromAdding = (index) => {
    let array = items
    array.splice(index, 1)

    setItems(array)
    setReRender(!reRender)
  }

  // console.log(items)

  const handleRemoveExistingItem = async (itemId) => {
    await axiosPrivate.delete(`${SERVER_URL}/startcalculators-item/${itemId}`)

    notification.success({ message: 'Uspešno obrisana stavka' })

    setTimeout(() => {
      window.location.reload()
    }, 300)
  }

  const handleMoveItem = async (itemId, direction) => {
    try {
      await axiosPrivate.put(
        `${SERVER_URL}/start-calculators-moveitem?direction=${direction}&&itemId=${itemId}`
      )

      notification.success({ message: 'Uspešno pomerena stavka' })

      setTimeout(() => {
        window.location.reload()
      }, 300)
    } catch (error) {
      console.log(error)
    }
  }

  const handleChangeImageLinkOldItem = (e, index) => {
    let array = oldItems
    array[index].imageLink = e.target.value

    setOldItems(array)
    setReRender(!reRender)
  }

  const handleCancelOldItems = () => {
    setIsVisibleModalOldItems(false)
  }

  const handleSelectImageOldItem = (image) => {
    let arr = oldItems

    arr[itemIndex].image = image

    setOldItems(arr)
    setIsVisibleModalOldItems(false)
  }

  const handleShowModalOldItems = (e) => {
    setIsVisibleModalOldItems(true)
  }

  const handleChangeTextAreaOldItem = (e, index) => {
    let array = oldItems

    array[index].description = e.target.value

    setOldItems(array)
    setReRender(!reRender)
  }

  const handleChangeBoldOldItem = (e, index) => {
    let array = oldItems
    array[index].bold = !array[index].bold

    setOldItems(array)
    setReRender(!reRender)
  }

  const handleChangeItalicOldItem = (e, index) => {
    let array = oldItems
    array[index].italic = !array[index].italic

    setOldItems(array)
    setReRender(!reRender)
  }

  const handleChangeTextColorOldItem = (e, index) => {
    let array = oldItems
    array[index].color = e

    setOldItems(array)
    setReRender(!reRender)
  }

  const handleChangeTextFontSizeOldItem = (e, index) => {
    let array = oldItems
    array[index].fontSize = e

    setOldItems(array)
    setReRender(!reRender)
  }

  const handleChangeMarginTopOldItem = (e, index) => {
    let array = oldItems
    array[index].marginTop = e

    setOldItems(array)
    setReRender(!reRender)
  }

  const handleChangeMarginBottomOldItem = (e, index) => {
    let array = oldItems
    array[index].marginBottom = e

    setOldItems(array)
    setReRender(!reRender)
  }

  const handleSubmit = async () => {
    try {
      let data
      if (editMode) {
        data = {
          title,
          featureImage: selectedFeatureImage?._id,
          marketingImage: selectedMarketingImage?._id,
          featureVideo: selectedFeatureVideo?._id,
          marketingVideo: selectedMarketingVideo?._id,
          marketingLink: marketingLink,
          textField: textField,
          newItems: items,
          oldItems,
        }
      } else {
        data = {
          title,
          featureImage: selectedFeatureImage?._id,
          marketingImage: selectedMarketingImage?._id,
          featureVideo: selectedFeatureVideo?._id,
          marketingVideo: selectedMarketingVideo?._id,
          marketingLink: marketingLink,
          textField: textField,
          items,
        }
      }

      if (!data.title) {
        notification.error({ message: 'Unesite naslov posta!' })
        return
      }
      if (!data.featureImage && !data.featureVideo) {
        notification.error({ message: 'Izaberite naslovnu sliku/video!' })
        return
      }
      if (!data.marketingImage && !data.marketingVideo) {
        notification.error({ message: 'Izaberite marketing sliku/video!' })
        return
      }
      if (!data.marketingLink) {
        data.marketingLink = 'https://betkotip.com'
      }

      if (!data.textField) {
        notification.error({ message: 'Unesite text polje!' })
        return
      }

      if (data.items) {
        for (let i = 0; i < data.items.length; i++) {
          if (data.items[i].type === 'img') {
            if (data.items[i].image === '') {
              notification.error({
                message: 'Stavka ne može postojati bez izabrane slike!',
              })
              return
            }
          }

          if (data.items[i].type === 'text') {
            if (data.items[i].description === '') {
              notification.error({
                message: 'Stavka ne može postojati bez opisa!',
              })
              return
            }
            if (data.items[i].color === '') {
              notification.error({
                message: 'Stavka ne može postojati bez odabrane boje!',
              })
              return
            }
          }
        }
      }

      if (data.oldItems) {
        for (let i = 0; i < data.oldItems.length; i++) {
          if (data.oldItems[i].type === 'img') {
            if (data.oldItems[i].image === '') {
              notification.error({
                message: 'Stavka ne može postojati bez izabrane slike!',
              })
              return
            }
          }

          if (data.oldItems[i].type === 'text') {
            if (data.oldItems[i].description === '') {
              notification.error({
                message: 'Stavka ne može postojati bez opisa!',
              })
              return
            }
            if (data.oldItems[i].color === '') {
              notification.error({
                message: 'Stavka ne može postojati bez odabrane boje!',
              })
              return
            }
          }
        }
      }
      setIsSaveButtonDisabled(true)
      if (editMode) {
        await axiosPrivate.put(`${SERVER_URL}/start-calculators`, data)
        notification.success({ message: 'Uspešno izmenjena stranica.' })
      } else {
        await axiosPrivate.post(`${SERVER_URL}/start-calculators`, data)
        notification.success({ message: 'Uspešno objavljena stranica.' })
      }

      setTimeout(() => {
        window.location.reload()
      }, 300)
    } catch (error) {
      notification.error({ message: error })
    }
  }

  const fetchData = async () => {
    let singlePost = await axiosPrivate.get(`${SERVER_URL}/start-calculators`)

    if (singlePost && singlePost.data) {
      setEditMode(true)
      setData(singlePost.data)
      setTitle(singlePost.data.title)
      setTextField(singlePost.data.textField)
      setSelectedFeatureImage(singlePost.data.featureImage)
      setSelectedMarketingImage(singlePost.data.marketingImage)
      setSelectedFeatureVideo(singlePost.data.featureVideo)
      setSelectedMarketingVideo(singlePost.data.marketingVideo)
      setMarketingLink(singlePost.data.marketingLink)
      setOldItems(singlePost.data.items)
    }
  }

  const handleShowModal = (e) => {
    if (e === 'featureVideo') {
      setVideoType(e)
      setIsVisibleVideoModal(true)
    } else if (e === 'marketingVideo') {
      setVideoType(e)
      setIsVisibleVideoModal(true)
    } else {
      setImageType(e)
      setIsVisibleModal(true)
    }
  }

  const handleCancel = () => {
    setIsVisibleModal(false)
    setIsVisibleVideoModal(false)
  }

  const [pageNumber, setPageNumber] = useState(1)
  const [totalPages, setTotalPages] = useState(null)

  const paginationProps = {
    currentPage: pageNumber,
    setCurrentPage: setPageNumber,
    buttonLimit: 3,
    totalPages: totalPages,
  }

  const fetchImages = async () => {
    try {
      const imagesArr = await Axios.get(`${SERVER_URL}/images/get-all?page=${pageNumber}&limit=50`, {
        withCredentials: false,
      });
      setImages(imagesArr.data.results);
      setFilteredImages(imagesArr.data.results);
      setTotalPages(imagesArr.data.totalPages)
    } catch (error) {
      console.log("err", error);
    }
  };

  useEffect(() => {
    fetchImages()
    fetchVideos()
    fetchData()
  }, [pageNumber])

  const fetchVideos = async () => {
    try {
      const videosArr = await Axios.get(`${SERVER_URL}/videos/get-all`, {
        withCredentials: false,
      })

      setVideos(videosArr.data)
    } catch (error) {
      console.log('err', error)
    }
  }

  const handleSelectImage = (image) => {
    if (imageType === 'featureImage') {
      setSelectedFeatureImage(image)
    } else if (imageType === 'marketingImage') {
      setSelectedMarketingImage(image)
    } else if (imageType === 'itemImage') {
      let arr = items

      arr[itemIndex].image = image

      setItems(arr)
    }
    setIsVisibleModal(false)
  }

  const handleChangeTextArea = (e, index) => {
    let array = items
    array[index].description = e.target.value

    setItems(array)
  }

  const handleChangeTextColor = (e, index) => {
    let array = items
    array[index].color = e

    setItems(array)
  }

  const handleChangeTextFontSize = (e, index) => {
    let array = items
    array[index].fontSize = e

    setItems(array)
  }

  const handleChangeBold = (e, index) => {
    let array = items
    array[index].bold = !array[index].bold

    setItems(array)
  }

  const handleChangeItalic = (e, index) => {
    let array = items
    array[index].italic = !array[index].italic

    setItems(array)
  }

  const handleChangeMarginTop = (e, index) => {
    let array = items
    array[index].marginTop = e

    setItems(array)
  }

  const handleChangeMarginBottom = (e, index) => {
    let array = items
    array[index].marginBottom = e

    setItems(array)
  }

  const handleChangeImageLink = (e, index) => {
    let array = items
    array[index].imageLink = e.target.value

    setItems(array)
  }

  const handleShowVideoModal = () => {
    setIsVisibleVideoModal(true)
  }

  const handleShowVideoModalOldItems = () => {
    setIsVisibleVideoModalOldItems(true)
  }

  const handleChangeMarketingLink = (e) => {
    setMarketingLink(e.target.value)
  }

  const handleChangeTextField = (e) => {
    setTextField(e.target.value)
  }

  const handleSelectVideo = (video) => {
    if (videoType == 'featureVideo') {
      setSelectedFeatureVideo(video)
      setSelectedFeatureImage(null)
      setIsVisibleVideoModal(false)
      setReRender(!reRender)
    } else if (videoType == 'marketingVideo') {
      setSelectedMarketingVideo(video)
      setSelectedMarketingImage(null)
      setIsVisibleVideoModal(false)
      setReRender(!reRender)
    } else {
      let arr = items

      arr[itemIndex].video = video

      setItems(arr)

      setIsVisibleVideoModal(false)
      setReRender(!reRender)
    }
  }

  const handleSelectVideoOldItems = (video) => {
    let arr = oldItems

    console.log(video)

    arr[itemIndex].video = video

    setOldItems(arr)

    setIsVisibleVideoModalOldItems(false)
    setReRender(!reRender)
  }

  const filterImages = (e) => {
    if (e) {
      let filtered = images.filter((item) => item.imageType === e)

      setFilteredImages(filtered)
    } else {
      setFilteredImages(images)
    }
  }

  return (
    <div className={classes.container}>
      <h1 style={{ marginLeft: '10px' }}>POKRENI KALKULATORE STRANICA</h1>
      <div className={classes.main}>
        <p>Naslov:</p>
        <input
          value={title}
          onChange={(e) => {
            setTitle(e.target.value)
          }}
        ></input>
      </div>
      <div className={classes.main}>
        <p>Naslovna slika:</p>
        {selectedFeatureImage && selectedFeatureImage.url && (
          <img
            style={{ width: '50px' }}
            src={`${SERVER_URL}/${selectedFeatureImage.url}`}
          />
        )}
        <button
          className={classes.addImageBtn}
          onClick={() => {
            handleShowModal('featureImage')
          }}
        >
          {selectedFeatureImage && selectedFeatureImage.url
            ? selectedFeatureImage.url
            : 'Izaberite sliku'}
        </button>
        {selectedFeatureImage && selectedFeatureImage.url && (
          <Button
            icon={<DeleteOutlined />}
            type='primary'
            style={{
              padding: '0px 0px 0px 20px',
              width: '60px',
              marginLeft: '15px',
              marginTop: '8px',
              display: 'flex',
              alignItems: 'center',
            }}
            danger
            onClick={() => setSelectedFeatureImage(null)}
          ></Button>
        )}
        <button
          className={classes.addImageBtn}
          onClick={() => {
            handleShowModal('featureVideo')
          }}
        >
          {selectedFeatureVideo && selectedFeatureVideo.url
            ? selectedFeatureVideo.url
            : 'Izaberite video'}
        </button>
        {selectedFeatureVideo && selectedFeatureVideo.url && (
          <Button
            icon={<DeleteOutlined />}
            type='primary'
            style={{
              padding: '0px 0px 0px 20px',
              width: '60px',
              marginLeft: '15px',
              marginTop: '8px',
              display: 'flex',
              alignItems: 'center',
            }}
            danger
            onClick={() => setSelectedFeatureVideo(null)}
          ></Button>
        )}
      </div>
      <div className={classes.main}>
        <p>Marketing slika:</p>
        {selectedMarketingImage && selectedMarketingImage.url && (
          <img
            style={{ width: '50px' }}
            src={`${SERVER_URL}/${selectedMarketingImage.url}`}
          />
        )}
        <button
          className={classes.addImageBtn}
          onClick={() => {
            handleShowModal('marketingImage')
          }}
        >
          {selectedMarketingImage && selectedMarketingImage.url
            ? selectedMarketingImage.url
            : 'Izaberite sliku'}
        </button>
        {selectedMarketingImage && selectedMarketingImage.url && (
          <Button
            icon={<DeleteOutlined />}
            type='primary'
            style={{
              padding: '0px 0px 0px 20px',
              width: '60px',
              marginLeft: '15px',
              marginTop: '8px',
              display: 'flex',
              alignItems: 'center',
            }}
            danger
            onClick={() => setSelectedMarketingImage(null)}
          ></Button>
        )}
        <button
          className={classes.addImageBtn}
          onClick={() => {
            handleShowModal('marketingVideo')
          }}
        >
          {selectedMarketingVideo && selectedMarketingVideo.url
            ? selectedMarketingVideo.url
            : 'Izaberite video'}
        </button>
        {selectedMarketingVideo && selectedMarketingVideo.url && (
          <Button
            icon={<DeleteOutlined />}
            type='primary'
            style={{
              padding: '0px 0px 0px 20px',
              width: '60px',
              marginLeft: '15px',
              marginTop: '8px',
              display: 'flex',
              alignItems: 'center',
            }}
            danger
            onClick={() => setSelectedMarketingVideo(null)}
          ></Button>
        )}
      </div>
      <div className={classes.main}>
        <p>Marketing slika - link:</p>
        <Input
          value={marketingLink}
          className={classes.inputMarketingLink}
          onChange={(e) => {
            handleChangeMarketingLink(e)
          }}
        ></Input>
      </div>

      <div className={classes.main}>
        <p>Text polje (ispod komentariši):</p>
        <Input
          value={textField}
          className={classes.inputMarketingLink}
          onChange={(e) => {
            handleChangeTextField(e)
          }}
        ></Input>
      </div>

      <div>
        <button
          className={classes.addImageBtn}
          onClick={() => {
            setItems([
              ...items,
              {
                type: 'text',
                description: '',
                color: '',
                bold: false,
                italic: false,
                marginTop: 0,
                marginBottom: 0,
              },
            ])
          }}
        >
          Dodaj tekst
        </button>
        <button
          className={classes.addImageBtn}
          onClick={() => {
            let array = items
            let obj = { type: 'img', image: '' }
            array.push(obj)

            setItems([...array])
          }}
        >
          Dodaj sliku
        </button>
        <button
          className={classes.addImageBtn}
          onClick={() => {
            let array = items
            let obj = { type: 'video', video: '' }
            array.push(obj)

            setItems([...array])
          }}
        >
          Dodaj video
        </button>
      </div>

      {items && items.length > 0 && (
        <div className={classes.labelRow}>Stavke </div>
      )}

      {items &&
        items.length > 0 &&
        items.map((item, index) => {
          if (item.type === 'text') {
            return (
              <div className={classes.textitem}>
                <div className={classes.textItemLeft}>
                  <div className={classes.item}>
                    <p>Tekst:</p>
                    <TextArea
                      className={classes.textAreaInput}
                      onChange={(e) => {
                        handleChangeTextArea(e, index)
                      }}
                    ></TextArea>
                  </div>
                  <div className={classes.item}>
                    <p>Bold:</p>
                    <Checkbox
                      onChange={(e) => {
                        handleChangeBold(e, index)
                      }}
                      type='checkbox'
                    ></Checkbox>
                  </div>
                  <div className={classes.item}>
                    <p>Italic:</p>
                    <Checkbox
                      onChange={(e) => {
                        handleChangeItalic(e, index)
                      }}
                      type='checkbox'
                    ></Checkbox>
                  </div>
                  <div className={classes.item}>
                    <p>Boja teksta:</p>
                    <Select
                      onChange={(e) => {
                        handleChangeTextColor(e, index)
                      }}
                      className={classes.colorSelect}
                    >
                      <Option value='red'>Crvena</Option>
                      <Option value='blue'>Plava</Option>
                      <Option value='#001a4f'>Tamno plava</Option>
                      <Option value='#f92910'>Narandžasta</Option>
                      <Option value='#301934'>Ljubičasta</Option>
                      <Option value='green'>Zelena</Option>
                    </Select>
                  </div>
                  <div className={classes.item}>
                    <p>Veličina teksta:</p>
                    <Select
                      value={item.fontSize}
                      onChange={(e) => {
                        handleChangeTextFontSize(e, index)
                      }}
                      className={classes.colorSelect}
                    >
                      <Option value='10'>10px</Option>
                      <Option value='16'>16px</Option>
                      <Option value='20'>20px</Option>
                      <Option value='30'>30px</Option>
                      <Option value='40'>40px</Option>
                      <Option value='50'>50px</Option>
                    </Select>
                  </div>
                  <div className={classes.item}>
                    <p>Margine (top-bottom):</p>
                    <InputNumber
                      onChange={(e) => {
                        handleChangeMarginTop(e, index)
                      }}
                      type='number'
                      max={10}
                      className={classes.inputNumberMargin}
                      min={0}
                    ></InputNumber>
                    <InputNumber
                      onChange={(e) => {
                        handleChangeMarginBottom(e, index)
                      }}
                      type='number'
                      max={10}
                      className={classes.inputNumberMargin}
                      min={0}
                    ></InputNumber>
                  </div>
                </div>
                <Button
                  icon={<DeleteOutlined />}
                  type='primary'
                  style={{
                    padding: '0px 0px 0px 20px',
                    width: '60px',
                    marginLeft: '15px',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                  danger
                  onClick={() => handleRemoveFromAdding(index)}
                ></Button>
              </div>
            )
          } else if (item.type === 'img') {
            return (
              <div className={classes.imageitem}>
                <div className={classes.imageItemLeft}>
                  <img
                    style={{ width: '50px', borderRadius: '10px' }}
                    src={`${SERVER_URL}/${item.image.url}`}
                  />
                  <Input
                    onChange={(e) => {
                      handleChangeImageLink(e, index)
                    }}
                    className={classes.inputNumberMargin}
                    style={{ marginRight: '10px' }}
                  ></Input>
                  <button
                    className={classes.addImageBtn}
                    onClick={() => {
                      handleShowModal('itemImage')
                      setItemIndex(index)
                    }}
                  >
                    {items[index] && items[index].image.url
                      ? items[index].image.url
                      : 'Izaberite sliku'}
                  </button>
                </div>
                <Button
                  icon={<DeleteOutlined />}
                  type='primary'
                  style={{
                    padding: '0px 0px 0px 20px',
                    width: '60px',
                    marginLeft: '15px',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                  danger
                  onClick={() => handleRemoveFromAdding(index)}
                ></Button>
              </div>
            )
          } else {
            return (
              <div className={classes.imageitem}>
                <div className={classes.videoItemLeft}>
                  {item && item.video && item.video.url && (
                    <video
                      key={`${SERVER_URL}/${item.video.url}`}
                      width='320'
                      height='240'
                      controls
                    >
                      <source
                        src={`${SERVER_URL}/${item.video.url}`}
                        type='video/mp4'
                      />
                    </video>
                  )}
                  <button
                    className={classes.addImageBtn}
                    onClick={() => {
                      handleShowVideoModal()
                      setItemIndex(index)
                    }}
                  >
                    {items[index] && items[index]?.video?.url
                      ? items[index].video.url
                      : 'Izaberite video'}
                  </button>
                </div>
                <Button
                  icon={<DeleteOutlined />}
                  type='primary'
                  style={{
                    padding: '0px 0px 0px 20px',
                    width: '60px',
                    marginLeft: '15px',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                  danger
                  onClick={() => handleRemoveFromAdding(index)}
                ></Button>
              </div>
            )
          }
        })}

      {oldItems && oldItems.length > 0 && (
        <div className={classes.labelRow}>Stare stavke </div>
      )}

      {oldItems &&
        oldItems.length > 0 &&
        oldItems.map((item, index) => {
          if (item.type === 'text') {
            return (
              <div className={classes.textitem}>
                <div className={classes.textItemLeft}>
                  <div className={classes.arrows}>
                    {index !== 0 && (
                      <img
                        className={classes.svgimg}
                        src={svggore}
                        onClick={() => {
                          handleMoveItem(item._id, 'moveleft')
                        }}
                      />
                    )}
                    {index !== oldItems.length - 1 && (
                      <img
                        className={classes.svgimg}
                        src={svgdole}
                        onClick={() => {
                          handleMoveItem(item._id, 'moveright')
                        }}
                      />
                    )}
                  </div>
                  <div className={classes.item}>
                    <p>Tekst:</p>
                    <TextArea
                      className={classes.textAreaInput}
                      value={item.description}
                      onChange={(e) => {
                        handleChangeTextAreaOldItem(e, index)
                      }}
                    ></TextArea>
                  </div>
                  <div className={classes.item}>
                    <p>Bold:</p>
                    <Checkbox
                      checked={item.bold}
                      onChange={(e) => {
                        handleChangeBoldOldItem(e, index)
                      }}
                      type='checkbox'
                    ></Checkbox>
                  </div>
                  <div className={classes.item}>
                    <p>Italic:</p>
                    <Checkbox
                      checked={item.italic}
                      onChange={(e) => {
                        handleChangeItalicOldItem(e, index)
                      }}
                      type='checkbox'
                    ></Checkbox>
                  </div>
                  <div className={classes.item}>
                    <p>Boja teksta:</p>
                    <Select
                      value={item.color}
                      onChange={(e) => {
                        handleChangeTextColorOldItem(e, index)
                      }}
                      className={classes.colorSelect}
                    >
                      <Option value='red'>Crvena</Option>
                      <Option value='blue'>Plava</Option>
                      <Option value='#001a4f'>Tamno plava</Option>
                      <Option value='#f92910'>Narandžasta</Option>
                      <Option value='#301934'>Ljubičasta</Option>
                      <Option value='green'>Zelena</Option>
                    </Select>
                  </div>
                  <div className={classes.item}>
                    <p>Veličina teksta:</p>
                    <Select
                      value={item.fontSize}
                      onChange={(e) => {
                        handleChangeTextFontSizeOldItem(e, index)
                      }}
                      className={classes.colorSelect}
                    >
                      <Option value='10'>10px</Option>
                      <Option value='16'>16px</Option>
                      <Option value='20'>20px</Option>
                      <Option value='30'>30px</Option>
                      <Option value='40'>40px</Option>
                      <Option value='50'>50px</Option>
                    </Select>
                  </div>
                  <div className={classes.item}>
                    <p>Margine (top-bottom):</p>
                    <InputNumber
                      value={item.marginTop}
                      onChange={(e) => {
                        handleChangeMarginTopOldItem(e, index)
                      }}
                      type='number'
                      max={10}
                      className={classes.inputNumberMargin}
                      min={0}
                    ></InputNumber>
                    <InputNumber
                      value={item.marginBottom}
                      onChange={(e) => {
                        handleChangeMarginBottomOldItem(e, index)
                      }}
                      type='number'
                      max={10}
                      className={classes.inputNumberMargin}
                      min={0}
                    ></InputNumber>
                  </div>
                </div>
                <Button
                  icon={<DeleteOutlined />}
                  type='primary'
                  style={{
                    padding: '0px 0px 0px 20px',
                    width: '60px',
                    marginLeft: '15px',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                  danger
                  onClick={() => {
                    setSelectedItem(item._id)
                    setIsDelModalVisible(true)
                  }}
                ></Button>
              </div>
            )
          } else if (item.type === 'img') {
            return (
              <div className={classes.imageitem}>
                <div className={classes.imageitemLeft}>
                  <div className={classes.arrows}>
                    {index !== 0 && (
                      <img
                        className={classes.svgimg}
                        src={svggore}
                        onClick={() => {
                          handleMoveItem(item._id, 'moveleft')
                        }}
                      />
                    )}
                    {index !== oldItems.length - 1 && (
                      <img
                        className={classes.svgimg}
                        src={svgdole}
                        onClick={() => {
                          handleMoveItem(item._id, 'moveright')
                        }}
                      />
                    )}
                  </div>
                  <img
                    style={{ width: '50px', borderRadius: '10px' }}
                    src={`${SERVER_URL}/${item.image.url}`}
                  />
                  <Input
                    onChange={(e) => {
                      handleChangeImageLinkOldItem(e, index)
                    }}
                    value={item.imageLink}
                    className={classes.inputNumberMargin}
                    style={{ marginRight: '10px' }}
                  ></Input>
                  <button
                    className={classes.addImageBtn}
                    onClick={() => {
                      handleShowModalOldItems('itemImage')
                      setItemIndex(index)
                    }}
                  >
                    {oldItems[index] && oldItems[index].image.url
                      ? oldItems[index].image.url
                      : 'Izaberite sliku'}
                  </button>
                </div>
                <Button
                  icon={<DeleteOutlined />}
                  type='primary'
                  style={{
                    padding: '0px 0px 0px 20px',
                    width: '60px',
                    marginLeft: '15px',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                  danger
                  onClick={() => {
                    setSelectedItem(item._id)
                    setIsDelModalVisible(true)
                  }}
                ></Button>
              </div>
            )
          } else {
            return (
              <div className={classes.imageitem}>
                <div className={classes.videoItemLeft}>
                  {/* <div className={classes.arrows}>
                    {index !== 0 && (
                      <img
                        className={classes.svgimg}
                        src={svggore}
                        onClick={() => {
                          handleMoveItem(item._id, "moveleft");
                        }}
                      />
                    )}
                    {index !== oldItems.length - 1 && (
                      <img
                        className={classes.svgimg}
                        src={svgdole}
                        onClick={() => {
                          handleMoveItem(item._id, "moveright");
                        }}
                      />
                    )}
                  </div> */}
                  {item && item.video && item.video.url && (
                    <video
                      key={`${SERVER_URL}/${item.video.url}`}
                      width='320'
                      height='240'
                      controls
                    >
                      <source
                        src={`${SERVER_URL}/${item.video.url}`}
                        type='video/mp4'
                      />
                    </video>
                  )}
                  <button
                    className={classes.addImageBtn}
                    onClick={() => {
                      handleShowVideoModalOldItems()
                      setItemIndex(index)
                    }}
                  >
                    {items[index] && items[index]?.video?.url
                      ? items[index].video.url
                      : 'Izaberite video'}
                  </button>
                </div>
                <Button
                  icon={<DeleteOutlined />}
                  type='primary'
                  style={{
                    padding: '0px 0px 0px 20px',
                    width: '60px',
                    marginLeft: '15px',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                  danger
                  onClick={() => {
                    setSelectedItem(item._id)
                    setIsDelModalVisible(true)
                  }}
                ></Button>
              </div>
            )
          }
        })}
      <Modal
        width={'80%'}
        footer={[]}
        title='Kliknite sliku da biste je odabrali'
        visible={isVisibleModal}
        onCancel={handleCancel}
      >
        <div>
          <Select
            style={{ minWidth: '200px' }}
            onChange={(e) => {
              filterImages(e)
            }}
            defaultValue={''}
          >
            <Option value=''>Sve</Option>
            <Option value='home'>Početna strana</Option>
            <Option value='gallery'>Gallery</Option>
          </Select>
        </div>
        <div className={classes.imagesList}>
          {filteredImages &&
            filteredImages.length > 0 &&
            filteredImages.map((item, index) => {
              return (
                <div className={classes.imageItem}>
                  <img
                    onClick={() => {
                      handleSelectImage(item)
                    }}
                    className='img'
                    src={`${SERVER_URL}/${item.url}`}
                    alt={item.url}
                  />
                </div>
              )
            })}
           <Pagination {...paginationProps}/>
        </div>
      </Modal>

      <Modal
        width={'80%'}
        footer={[]}
        title='Kliknite sliku da biste je odabrali'
        visible={isVisibleModalOldItems}
        onCancel={handleCancelOldItems}
      >
        <div>
          <Select
            style={{ minWidth: '200px' }}
            onChange={(e) => {
              filterImages(e)
            }}
            defaultValue={''}
          >
            <Option value=''>Sve</Option>
            <Option value='home'>Početna strana</Option>
            <Option value='gallery'>Gallery</Option>
          </Select>
        </div>
        <div className={classes.imagesList}>
          {filteredImages &&
            filteredImages.length > 0 &&
            filteredImages.map((item, index) => {
              return (
                <div className={classes.imageItem}>
                  <img
                    onClick={() => {
                      handleSelectImageOldItem(item)
                    }}
                    className='img'
                    src={`${SERVER_URL}/${item.url}`}
                    alt={item.url}
                  />
                </div>
              )
            })}
           <Pagination {...paginationProps}/>
        </div>
      </Modal>

      <Modal
        footer={[]}
        open={isDelModalVisible}
        title='Da li ste sigurni da želite da obrišete stavku?'
        onCancel={() => {
          setIsDelModalVisible(false)
        }}
      >
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-evenly',
            marginTop: '20px',
          }}
        >
          <Button
            onClick={(e) => {
              handleRemoveExistingItem(selectedItem)
              setIsDelModalVisible(false)
            }}
          >
            Obriši
          </Button>
          <Button
            onClick={(e) => {
              setIsDelModalVisible(false)
            }}
          >
            Odustani
          </Button>
        </div>
      </Modal>
      <Modal
        width={'80%'}
        footer={[]}
        title='VIDEO GALERIJA'
        visible={isVisibleVideoModal}
        onCancel={handleCancel}
      >
        <div className={classes.videosList}>
          {videos &&
            videos.length > 0 &&
            videos.map((item, index) => {
              return (
                <div className={classes.videoItem}>
                  <video width='320' height='240' controls>
                    <source
                      src={`${SERVER_URL}/${item.url}`}
                      type='video/mp4'
                    />
                  </video>
                  <Button
                    onClick={() => {
                      handleSelectVideo(item)
                    }}
                    className={classes.selectVideoButton}
                  >
                    Izaberite video
                  </Button>
                </div>
              )
            })}
        </div>
      </Modal>
      <Modal
        width={'80%'}
        footer={[]}
        title='VIDEO GALERIJA'
        visible={isVisibleVideoModalOldItems}
        onCancel={handleCancel}
      >
        <div className={classes.videosList}>
          {videos &&
            videos.length > 0 &&
            videos.map((item, index) => {
              return (
                <div className={classes.videoItem}>
                  <video width='320' height='240' controls>
                    <source
                      src={`${SERVER_URL}/${item.url}`}
                      type='video/mp4'
                    />
                  </video>
                  <Button
                    onClick={() => {
                      handleSelectVideoOldItems(item)
                    }}
                    className={classes.selectVideoButton}
                  >
                    Izaberite video
                  </Button>
                </div>
              )
            })}
        </div>
      </Modal>
      <div className={classes.statistikaFooter}>
        <button
          className={classes.submitButton}
          onClick={() => {
            handleSubmit()
          }}
          disabled={isSaveButtonDisabled}
        >
          Sačuvaj
        </button>
      </div>
    </div>
  )
}

export default AdminPokreniKalkulatore
