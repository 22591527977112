import React from 'react'
import classes from './SideImages.module.css'


// TOOD: Get Images from server 
const SideImages = () => {
  return (
    <div className={classes.sideImages}>
      <div>
        <img src='/tiger.webp' />
      </div>
      <div>
        <img src='/tiger.webp' />
      </div>
      <div>
        <img src='/tiger.webp' />
      </div>
    </div>
  )
}

export default SideImages
