export const dateWithHours = (createdAt) => {
  const date = new Date(createdAt)

  const year = date.getFullYear()
  const month = String(date.getMonth() + 1).padStart(2, '0')
  const day = String(date.getDate()).padStart(2, '0')

  const hours = String(date.getHours()).padStart(2, '0')
  const minutes = String(date.getMinutes()).padStart(2, '0')

  const formattedDateTime = `${day}.${month}.${year}. ${hours}:${minutes}`
  return formattedDateTime
}
