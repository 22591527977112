export const renderTextWithLinks = (text) => {
    // Regular expression to match URLs
    const urlRegex = /(https?:\/\/[^\s]+)/g

    // Replace URLs with anchor tags
    let html = text.replace(
      urlRegex,
      (url) =>
        `<a href="${url}" target="_blank" rel="noopener noreferrer">${url}</a>`
    )

    // Replace newlines with <br> tags
    html = html.replace(/\n/g, '<br>')

    return { __html: html }
  }
