import { useNavigate } from 'react-router-dom'
import classes from './betKursButtons.module.css'
import { useState, useEffect } from 'react'
import Axios from 'axios'
const SERVER_URL = process.env.REACT_APP_SERVER_URL

const BetKurs = () => {
  const navigate = useNavigate()
  const [items, setItems] = useState([])
  const [isListVisible, setIsListVisible] = useState(false)
  const [selectedItemId, setSelectedItemId] = useState(null)

  const fetchItems = async () => {
    const data = await Axios.get(`${SERVER_URL}/betcourses-menu`)
    setItems(data.data)
  }

  useEffect(() => {
    fetchItems()
  }, [])

  const toggleListVisibility = () => {
    setIsListVisible((prevState) => !prevState)
  }

  return (
    <>
      <div className={classes.toggleButtonContainer}>
        <button onClick={toggleListVisibility} className={classes.toggleButton}>
          {isListVisible ? 'Zatvori listu kurseva' : 'Pogledaj listu kurseva'}
        </button>
      </div>
      <div
        className={`${classes.container} ${
          isListVisible ? classes.visible : ''
        }`}
      >
        {items &&
          items.length > 0 &&
          items.map((item, index) => (
            <ul key={index}>
              <li className={classes.liTitle}>
                <p style={{ margin: '0', padding: '0' }}>{item.title}</p>
              </li>
              {item.items &&
                item.items.length > 0 &&
                item.items.map((courseitem, courseindex) => (
                  <li
                    key={courseindex}
                    onClick={() => {
                      setSelectedItemId(courseitem._id)
                      navigate(`/bet_kurs/${courseitem._id}`)
                    }}
                    className={`${classes.liItem} ${
                      selectedItemId === courseitem._id ? classes.selected : ''
                    }`}
                  >
                    {courseitem.title}
                  </li>
                ))}
            </ul>
          ))}
      </div>
    </>
  )
}

export default BetKurs
