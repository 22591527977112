import React, { useContext, useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import BetkoShopLayout from './BetkoShopLayout'
import classes from './BetkoShopUslovi.module.css'
import { betkoShopUsloviData } from './BetkoShopUsloviData'
import { Button, Form, Input, message, Modal } from 'antd'
import axios from '../../config/axios'
import LoginModalNovo from '../../components/LoginModalNovo'
import AuthContext from '../../context/AuthProvider'
import useRefreshToken from '../../hooks/useRefreshToken'
const SERVER_URL = process.env.REACT_APP_SERVER_URL

const BetkoShopUslovi = () => {
  const { auth } = useContext(AuthContext)
  const [isSellerModalVisible, setIsSellerModalVisible] = useState(false)
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768)
  const [searchParams] = useSearchParams()
  const [isOpenLoginModal, setIsOpenLoginModal] = useState(false)
  const refresh = useRefreshToken()
  const menu = searchParams.get('menu')
  const title = searchParams.get('title')
  const [form] = Form.useForm()
  const navigate = useNavigate()

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768)
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const handleRequestShop = async () => {
    try {
      const values = await form.validateFields()
      const response = await axios.post(
        `${SERVER_URL}/user/request-shop/${auth._id}`,
        { ...values }
      )
      message.success(response?.data || 'Zahtev je uspešno poslat!')
      setIsSellerModalVisible(false)
      refresh()
    } catch (error) {
      message.error(
        error?.response?.data?.message ||
          'Greška, pokušajte kasnije ili nas kontaktirajte!'
      )
    }
  }

  return (
    <BetkoShopLayout>
      <LoginModalNovo
        setIsOpenLoginModal={setIsOpenLoginModal}
        isOpenLoginModal={isOpenLoginModal}
        title='Da bi ste postali prodavac morate se ulogovati!'
      />
      <Modal
        title='Unesite podatke o kompaniji'
        open={isSellerModalVisible} // Set to true to display the modal for this example
        onOk={handleRequestShop}
        onCancel={() => {
          setIsSellerModalVisible(false)
          form.resetFields()
        }}
        footer={[
          <Button
            key='back'
            onClick={() => {
              setIsSellerModalVisible(false)
              form.resetFields()
            }}
          >
            Otkaži
          </Button>,
          <Button key='submit' type='primary' onClick={handleRequestShop}>
            Potvrdi
          </Button>,
        ]}
      >
        <Form form={form} layout='vertical' className={classes.cf}>
          <Form.Item
            name='companyName'
            label='Naziv kompanije'
            rules={[
              { required: true, message: 'Molimo unesite naziv kompanije!' },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name='companyAddress'
            label='Adresa kompanije'
            rules={[
              { required: true, message: 'Molimo unesite adresu kompanije!' },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name='companyPlace'
            label='Mesto kompanije'
            rules={[
              { required: true, message: 'Molimo unesite mesto kompanije!' },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name='companyPIB'
            label='PIB'
            rules={[
              { required: true, message: 'Molimo unesite PIB!' },
              { len: 9, message: 'PIB mora imati tačno 9 cifara!' },
              {
                pattern: /^[0-9]+$/,
                message: 'PIB može sadržavati samo cifre!',
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name='companyMIB'
            label='Matični broj'
            rules={[
              { required: true, message: 'Molimo unesite Matični broj!' },
              { len: 8, message: 'Matični broj mora imati tačno 8 cifara!' },
              {
                pattern: /^[0-9]+$/,
                message: 'Matični broj može sadržavati samo cifre!',
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
      <div className={`${classes.pageContainer} ${classes[menu]}`}>
        <div className={classes.sticky}>
          <div className={classes.menuTitle}>
            <h2>{menu.replaceAll('-', ' ')}</h2>
          </div>
        </div>
        <div className={classes.navBtns}>
          <button
            onClick={() =>
              navigate(
                `/betko_shop/uslovi?menu=${menu}&title=uslovi-za-korisnike`
              )
            }
          >
            USLOVI ZA KORISNIKE
          </button>
          <button
            onClick={() =>
              navigate(`/betko_shop/uslovi?menu=${menu}&title=saznaj-vise`)
            }
          >
            SAZNAJ VIŠE
          </button>
          <button
            onClick={() =>
              navigate(
                `/betko_shop/uslovi?menu=${menu}&title=uslovi-za-ponudjace`
              )
            }
          >
            USLOVI ZA PONUĐAČE
          </button>
        </div>
        <div className={`${classes.navBtns} ${classes.becomeSellerBtn}`}>
          {auth.accessAdminShop === 'requested' ? (
            <button>ČEKA SE ODOBRENJE</button>
          ) : auth.accessAdminShop === 'approved' ? (
            <button
              onClick={() => !isMobile && navigate('/betko-shop-admin/home')}
            >
              KLIKOM NA OVO POLJE (ISKLJUČIVO NA RAČUNARU, NE I NA MOBILNOM
              TELEFONU) MOŽETE PRISTUPITI VAŠEM ADMINISTRATORSKOM PROFILU.
            </button>
          ) : (
            <button
              onClick={() => {
                auth?._id
                  ? setIsSellerModalVisible(true)
                  : setIsOpenLoginModal(true)
              }}
            >
              KLIKNI I POSTANI{' '}
              {menu === 'licitacije'
                ? 'LICITATOR'
                : menu === 'shop'
                ? 'PRODAVAC'
                : 'ORGANIZATOR PUTA'}
            </button>
          )}
        </div>
        <h3>
          {title
            .replaceAll('-', ' ')
            .replaceAll('vise', 'više')
            .replaceAll('ponudjace', 'ponuđače')}
        </h3>

        <div className={classes.textWrapper}>
          {betkoShopUsloviData[menu][title].split('\n').map((line, idx) => (
            <p key={idx}>{line}</p>
          ))}{' '}
        </div>
      </div>
    </BetkoShopLayout>
  )
}

export default BetkoShopUslovi
