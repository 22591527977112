import React, { useState } from 'react'
import classes from './Header.module.css'
import { useNavigate } from 'react-router-dom'
import { useAuthContext } from '../../../context/AuthProvider'
import OpenAndConquerModal from '../../openAndConquer/OpenAndConquerModal'
import PricesModal from '../../PricesModal'

const Header = ({ setIsSidebarActive, isSidebarActive, onClose }) => {
  const [openMenu, setOpenMenu] = useState(null)
  const navigate = useNavigate()
  const { auth } = useAuthContext()

  const handleMenuToggle = (menuIndex) => {
    setOpenMenu((prevMenu) => (prevMenu === menuIndex ? null : menuIndex))
  }

  const [isModalOpen, setIsModalOpen] = useState(false)

  // TODO: ADD LINKS

  return (
    <div className={classes.header}>
      <div className={classes.headerInnerWrapper}>
        <div className={classes.headerLeft}>
          <div
            className={`imageWrapper ${classes.logoWrapper}`}
            onClick={() => {
              navigate('/')
              setIsSidebarActive(false)
            }}
          >
            <img src='/logo-new-big.svg' alt='betkotips logo' />
          </div>
          <div className={classes.desktopLinks}>
            <div
              className={classes.menuItem}
              onMouseEnter={() => setOpenMenu(1)}
              onMouseLeave={() => setOpenMenu(null)}
            >
              <button
                className={`${classes.headerMenuBtn} ${
                  openMenu === 1 ? classes.activeMenuItem : ''
                }`}
                onClick={() => handleMenuToggle(1)}
              >
                Betko Family
                <div className='image-wrapper'>
                  <img src='/icons/submenu.svg' alt='Betkogram' />
                </div>
              </button>
              {openMenu === 1 && (
                <ul className={classes.subMenu}>
                  <li onClick={() => navigate('/betko_family')}>
                    Šta je betko family?
                  </li>
                  <li onClick={() => navigate('/betko_plus')}>Betko plus</li>
                </ul>
              )}
            </div>
            {/* Betkogram */}
            <div
              className={classes.menuItem}
              onMouseEnter={() => setOpenMenu(2)}
              onMouseLeave={() => setOpenMenu(null)}
            >
              <button
                className={`${classes.headerMenuBtn} ${
                  openMenu === 2 ? classes.activeMenuItem : ''
                }`}
                onClick={() => handleMenuToggle(2)}
              >
                Betkogram
                <div className='image-wrapper'>
                  <img src='/icons/submenu.svg' alt='Betkogram' />
                </div>
              </button>
              {openMenu === 2 && (
                <ul className={classes.subMenu}>
                  <li onClick={() => navigate('/glavne-kartice')}>
                    Glavne kartice
                  </li>
                  <li onClick={() => navigate('/sportske-price')}>
                    Sportske priče
                  </li>
                  <li onClick={() => navigate('/info_blok')}>Info blok</li>
                  <li onClick={() => navigate('/bet_portal')}>Bet portal</li>
                  <li onClick={() => navigate('/statistika')}>Statistika</li>
                  <li onClick={() => navigate('/vasi_predlozi')}>
                    Vaši predlozi
                  </li>
                  <li
                    onClick={() =>
                      navigate('/bet_portal/653e47fa52a098b10de971f2')
                    }
                  >
                    BET
                  </li>
                  <li
                    onClick={() =>
                      navigate('/bet_portal/65611f28f4ca9761eb5249ad')
                    }
                  >
                    NET
                  </li>
                  <li onClick={() => navigate('/semafori')}>Semafori</li>
                </ul>
              )}
            </div>
            {/* Korisnicki paketi */}

            <div
              className={classes.menuItem}
              onMouseEnter={() => setOpenMenu(3)}
              onMouseLeave={() => setOpenMenu(null)}
            >
              <button
                className={`${classes.headerMenuBtn} ${
                  openMenu === 3 ? classes.activeMenuItem : ''
                }`}
                onClick={() => handleMenuToggle(3)}
              >
                Korisnički paketi
                <div className='image-wrapper'>
                  <img src='/icons/submenu.svg' alt='Betkogram' />
                </div>
              </button>
              {openMenu === 3 && (
                <ul className={classes.subMenu}>
                  <li onClick={() => navigate('/bet_kurs_tutorijal')}>
                    Opis BET kursa
                  </li>
                  <li onClick={() => navigate('/bet_kalkulatori')}>
                    Opis BET kalkulatora
                  </li>
                  <li onClick={() => navigate('/bet_kurs')}>Šta je BET kurs</li>
                  <li
                    onClick={() =>
                      navigate('/korisnicki_paketi/65bae5faa86ff9a51a6b1b2b')
                    }
                  >
                    Šta je BET kalkulator
                  </li>
                  <li onClick={() => navigate('/bet_kalkulatori_tutorijal')}>
                    BET kalkulatori
                  </li>
                  <li
                    onClick={() =>
                      navigate('/korisnicki_paketi/65bae51ba86ff9a51a6b1ab4')
                    }
                  >
                    BET kurs i sistemi
                  </li>
                  <li onClick={() => navigate('/bet_kurs_novo')}>PREMIUM</li>
                </ul>
              )}
            </div>
            <div
              className={classes.menuItem}
              onMouseEnter={() => setOpenMenu(4)}
              onMouseLeave={() => setOpenMenu(null)}
            >
              <button
                className={`${classes.headerMenuBtn} ${
                  openMenu === 4 ? classes.activeMenuItem : ''
                }`}
              >
                Kupi paket{' '}
                <div className='image-wrapper'>
                  <img src='/icons/submenu.svg' alt='kupi paket' />
                </div>
              </button>
              {openMenu === 4 && (
                <ul className={classes.subMenu}>
                  <li onClick={() => navigate('/korisnicki_paketi')}>
                    Kupi paket
                  </li>
                  <li
                    onClick={() =>
                      navigate('/korisnicki_paketi/65bac125a86ff9a51a6b110f')
                    }
                  >
                    Kupovina putem uplatnice
                  </li>
                </ul>
              )}
            </div>

            <button
              className={`${classes.headerMenuBtn}`}
              onClick={() => setIsModalOpen(true)}
            >
              Otvori i osvoji
            </button>
            <OpenAndConquerModal
              isOpen={isModalOpen}
              setIsOpen={setIsModalOpen}
            />
            <div
              className={classes.menuItem}
              onMouseEnter={() => setOpenMenu(5)}
              onMouseLeave={() => setOpenMenu(null)}
            >
              <button
                className={`${classes.headerMenuBtn} ${
                  openMenu === 5 ? classes.activeMenuItem : ''
                }`}
              >
                Betko shop
                <div className='image-wrapper'>
                  <img src='/icons/submenu.svg' alt='betko-shop' />
                </div>
              </button>
              {openMenu === 5 && (
                <ul className={classes.subMenu}>
                  <li onClick={() => navigate('/betko_shop')}>Betko Shop</li>
                  <li
                    onClick={() => navigate('/betko_shop/shop?shopType=shop')}
                  >
                    Shop
                  </li>
                  <li
                    onClick={() =>
                      navigate('/betko_shop/shop?shopType=auction')
                    }
                  >
                    Licitacije
                  </li>
                  <li
                    onClick={() =>
                      navigate('/betko_shop/shop?shopType=sport-tourist')
                    }
                  >
                    Sport Turist
                  </li>
                </ul>
              )}
            </div>

            <button
              className={`${classes.headerMenuBtn}`}
              onClick={() => navigate('/rezultati_uzivo')}
            >
              <div className='image-wrapper'>
                <img src='/icons/dot.svg' alt='Rezulati uzivo' />
              </div>
              Rezultati uživo
            </button>
          </div>
        </div>
        <div className={classes.headerRight}>
          <span
            className={classes.headerLink}
            onClick={() => {
              navigate('/18+')
              setIsSidebarActive(false)
            }}
          >
            18+
          </span>
          <button
            className={`${classes.startMenuBtn} ${
              isSidebarActive ? classes.activeStartMenuBtn : ''
            }`}
            onClick={() => {
              setIsSidebarActive((curr) => !curr)
            }}
          >
            <div className='image-wrapper'>
              <img
                src={`/icons/start-menu${isSidebarActive ? '-active' : ''}.svg`}
                alt='Start Meni'
              />
            </div>
            Start Meni
          </button>

          {auth && Object.keys(auth).length < 1 ? (
            <button
              onClick={() => navigate('/registracija_i_logovanje')}
              className={classes.authBtn}
            >
              Prijavi se
            </button>
          ) : (
            <button
              onClick={() => navigate('/moj_nalog')}
              className={classes.authBtn}
            >
              Moj nalog
            </button>
          )}
        </div>
      </div>
    </div>
  )
}

export default Header

// import React from 'react'
// import classes from './Header.module.css'

// const Header = () => {
//   return (
//     <div className={classes.header}>
//       <div className={classes.headerInnerWrapper}>
//         <div className={classes.headerLeft}>
//           <div className={`imageWrapper ${classes.logoWrapper}`}>
//             <img src='/logo-new-big.svg' alt='betkotips logo' />
//           </div>
//           <div className={classes.desktopLinks}>

//           </div>
//         </div>
//         <div className={classes.headerRight}>
//           <a href='/18+'>18+</a>
//           <button className={classes.startMenuBtn}>
//             <div className='image-wrapper'>
//               <img src='/start-menu-btn.svg' alt='Start Meni' />
//             </div>
//             Start Meni
//           </button>
//         </div>
//       </div>
//     </div>
//   )
// }

// export default Header
